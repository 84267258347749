<template>
  <section>
    <b-card>
      <b-row>
        <b-col sm="4">
          <h4 class="text-left">
            Resources Report
          </h4>
          <h5 class="text-left text-muted">
            License Start Date {{ licenseStartDate }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          class="d-flex justify-content-end"
        >
          <b-form-group 
            v-if="!isProgramTraining"
            label="Select Groups to filter by" 
            label-for="groups"
            class="mr-1 mb-0 mt-25 group-filter"
          >
            <v-select
              v-model="selectedGroups"
              placeholder="Please select a group"
              :options="programGroups"
              :reduce="(option) => option.id"
              label="name"
              :loading="isLoading"
              transition="fade"
            />
          </b-form-group>
          <validation-provider
            v-slot="validationContext"
            vid="time"
            :rules="{ required: true }"
            name="Date"
            class="mr-1"
          >
            <label for="datepicker-from">From</label>
            <b-form-datepicker
              v-model="fromDate"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            vid="time"
            :rules="{ required: true }"
            name="Date"
          >
            <label for="datepicker-to">To</label>
            <b-form-datepicker
              v-model="toDate"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
      <aom-skeleton-loader v-if="isLoading" />
      <section
        v-else
        class="mt-2"
      >
        <apexchart
          type="bar"
          :options="options"
          :series="options.series"
          height="350"
        />
        <b-row align-h="end">
          <b-col
            sm="4"
            class="text-right"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mt-1"
              right
            >
              <template #button-content>
                <b-button
                  variant="outline-primary"
                  class="ml-2"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadReport('csv')">
                <span class="align-middle ml-50">Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadReport('xlsx')">
                <span class="align-middle ml-50">Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <vue-good-table
          class="mt-3"
          mode="remote"
          :is-loading="isTableLoading"
          :columns="columns"
          :rows="rows"
          :rtl="isRtl"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
          }"
        
          style-class="vgt-table striped"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <div
              v-if="props.column.field === 'action'"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap vgt-center-align"
            >
              <div class="align-items-center mb-0 mt-0">
                <b-button
                  variant="outline-none"
                  class="btn-icon"
                  :to="{
                    name: 'champion-program-resources',
                    params: { id: defaultProgramId },
                  }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                  />
                </b-button>
              </div>
            </div>
            <div
              v-else-if="props.column.field === 'full_name'"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap vgt-center-align"
            >
              <div class="align-items-center mb-0 mt-0">
                <router-link
                  :to="{
                    name: 'champion-program-participant',
                    params: { id: defaultProgramId, participantId: props.row.id },
                  }"
                >
                  {{ props.row.full_name }}
                </router-link>
              </div>
            </div>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
    
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <table-pagination
              :per-page="perPage"
              :total="total"
              @perPageChanged="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
              @pageChanged="
                (value) => props.pageChanged({ currentPage: value })
              "
            />
          </template>
        </vue-good-table>
      </section>
    </b-card>
  </section>
</template>
    
<script>
import { 
  BButton,
  BCard,
  BFormDatepicker,
  BCol,
  BRow,
  BFormInvalidFeedback,
  BFormGroup,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, PRIMARY_DATE_FORMAT } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRolesDisplay, participantRolesDisplay } from '@models/userRoles';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import {reportService, groupsService }  from "@/services";
import { mapGetters } from 'vuex';
import { STATUS_COLOR } from "@/libs/utils";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import vSelect from "vue-select";     
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import {
  getValidationState,
  localeDateStringFromIsoDateTime
} from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import {programTypes} from '@models';
  
export default {
  name: 'MonthlyResourcesReport',
  components: {
    BButton,
    BCard,
    VueGoodTable,
    TablePagination,
    AomSkeletonLoader,
    BFormDatepicker,
    ValidationProvider,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      columns: [
        {
          label: "Resource",
          field: "translations.0.title",
          filterOptions: {
            enabled: true,
            placeholder: "Resource",
          },
          width: "15em",
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay,
            filterFn: this.participantStatus
          },
          width: "15em",
        },
        {
          label: "Unique Downloads/Views",
          field: "program_resources.0.unique_user_downloads_count",
          width: "10em",
          sortable: false
        },
        {
          label: "Total",
          field: "program_resources.0.user_downloads_count",
          width: "10em",
          sortable: false
        },
        {
          field: 'action',
          width: "6em",
          sortable: false
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      fromDate: new Date(),
      toDate: new Date(),
      selectedGroups: [],
      programGroups: [],
      options: {
        colors: ["#4c546d", "#9bcc65"],
        series: [],
        xaxis: {},
        yaxis: {
          title: {
            text: 'Downloads/Views'
          },
          labels: {
            formatter: function(val) {
              return Math.floor(val);
            }
          }
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
      isTableLoading: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
    licenseStartDate() {
      return localeDateStringFromIsoDateTime(this.defaultProgram?.licence_start_date);
    },
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  watch: {
    defaultProgram: {
      handler(n) {
        if(n) {
          this.loadPageData();
        }
      },
      deep: true,
      immediate: true
    },
    selectedGroups(n) {
      if(n) {
        this.loadChartTableData();       
      }
    },
    fromDate(n) {
      if(n) {
        this.loadChartTableData();
      }
    },
    toDate(n) {
      if(n) {
        this.loadChartTableData();
      }
    }
  },
  methods: {
    async loadChartTableData() {
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadItems(),
          this.loadChartData()
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async loadPageData() {
      this.fromDate = this.defaultProgram?.licence_start_date;
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadChartData(),
          this.loadItems(),
          this.loadProgramGroup()
        ]).then(() => {
          this.initDatePicker();
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    initDatePicker() {
      const elements = document.getElementsByName("vgt-created_at");
      elements.forEach(function (input) {
        flatPickr(input, {
          enableTime: false,
          dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
          allowInput: true,
          altInput: true,
          altFormat: PRIMARY_DATE_FORMAT,
          mode: "range",
        });
      });
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      if (params[0].type !== 'none') {
        if(params[0].field === 'translations.0.title') {
          this.sort = [{ field: 'translations.title', type: params[0].type }];
        } else {
          this.sort = [{ field: params[0].field, type: params[0].type }];
        }
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "translations.0.title",
        "role",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'role'){
            let roleIds = [];
            roleIds.push(params.columnFilters.role);
            columnFilters.push({
              field: col,
              value: roleIds
            });
          }  else if (col === 'translations.0.title') {
            columnFilters.push({
              field: 'translations.title',
              value: params.columnFilters[col]
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isTableLoading = true;
        const programId = this.$route.params.id;
        const response = await reportService.getDownloadsList(programId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort
        },
        this.toDate,
        this.fromDate,
        this.selectedGroups
        );
        const { items, total }  = response.data;
        this.total = total;
        const mappedItems = items.map(i => i = { 
          ...i,
          role: this.userRole(i.roles)
        });
        this.rows = mappedItems;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isTableLoading = false;
      }
                
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.id]);
        const uniqueRoles = [...new Set(roles)];
        return uniqueRoles.join(", ");
      }
      return '';
    },
    async loadChartData() {
      try {
        const programId = this.$route.params.id;
        const response = await reportService.getMonthlyDownloadsChartData(programId, {}, this.toDate, this.fromDate, this.selectedGroups );
        const {series, xaxis} = response.data;
        this.options.series =  series; 
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            max: xaxis.length,
            categories: xaxis
          }
        };
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    },
    async loadProgramGroup() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await groupsService.listGroups(programId);
        const {data} = response;
        const {items} = data;
        this.programGroups = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    onDownloadReport(type) {
      const dowloadLink =
        `${BE_API_URL}/programs/${this.$route.params.id}/reports/downloads/download/${type}` +
        `?search=${encodeURIComponent(
          JSON.stringify({
            page: this.page,
            columnFilters: this.columnFilters,
            sort: this.sort,
          })
        )}` +
        `&to=${JSON.stringify(this.toDate).slice(1, -1)}` +
        `&from=${JSON.stringify(this.fromDate).slice(1, -1)}`;
      window.document.location = dowloadLink;
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      localeDateStringFromIsoDateTime,
      getValidationState,
      total, perPage, page
    };
  }
};
</script>
    
    <style lang="scss">
    @import '@/assets/scss/vue/libs/vue-good-table.scss';
    </style>
    <style lang="scss" scoped>
    .group-filter {
      width: 25%;
      min-width: 25%;
    }
    ::v-deep .apexcharts-menu-item.exportCSV {
      display: none;
    }
    </style>
    